import {action, computed, decorate, observable} from 'mobx'
import autoStore from './autoStore'
import {IUser} from "../interfaces/IUser";
import {ICardType} from "../interfaces/ICardType";
import {IOrder} from "../interfaces/IOrder";
import {ICustomer} from "../interfaces/ICustomer";
import {customers} from "../api/customers";
import {users} from "../api/users";

class PersistentStore {
    IPDToken: string = ''
    apiUrl: string = process.env.REACT_APP_API_URL || ''
    browserInfo: BrowserInfo | undefined
    customer: ICustomer | undefined = undefined
    customers: ICustomer[] = []
    buyer: {name: string, email: string} = {name: '', email: ''}
    chosenCardType: ICardType | undefined = undefined
    customerView: string = 'grid'
    displayArchived: boolean = false
    isImpersonating = false
    languageSelected:string = 'nl'
    order: IOrder | undefined = undefined
    pageLength: number = 15
    pageLengthOptions: number[] = [10,20,50,100,150]
    selectedCustomer: ICustomer | undefined = undefined
    showTrialInfo: boolean = true
    token: string = ''
    user: IUser | undefined = undefined
    userWhoIsImpersonating: {user: IUser, token: string, customers: ICustomer[], selectedCustomer: ICustomer | undefined} | undefined = undefined

    constructor() {
        // store changes to the state in localStorage
        autoStore(this, 'persistentStore')
    }

    setCustomerView = (view: string) => {
        if (!['grid', 'list'].includes(view)) {
            console.error('Invalid option provided for customerview: ' + view)
        }

        this.customerView = view
    }

    setBrowserInfo(info: BrowserInfo) {
        this.browserInfo = info
    }

    setOrder(order: IOrder | undefined){
        this.order = order
    }

    setChosenCardType(chosenCardType: ICardType | undefined){
        this.chosenCardType = chosenCardType
    }

    // empty...
    emptyStore() {
        this.customers = []
        this.customer = undefined
        this.selectedCustomer = undefined
        this.token = ''
        this.showTrialInfo = true
        this.user = undefined
        this.buyer = {name: '', email: ''}
    }

    /**
     * Get the customer data from the server and update the relevant customer in store.customers
     *
     * @param customerPublicId
     */
    updateCustomerFromServer(customerPublicId: string) {
        customers.getByPublicId(customerPublicId)
            .then(customer => {
                this.updateCustomer(customer)
            })
            .catch(error => {
                console.error('Could not update customer in store with data fetched from API')
            })
    }

    updateCustomer(updatedCustomer: ICustomer) {
        // remove customer from this.customers
        const customersWithoutTheOldOne = this.customers.filter(customer => customer.id !== updatedCustomer.id)
        // add it again
        this.customers = customersWithoutTheOldOne.concat(updatedCustomer)
    }

    /**
     * Get the User information from the server
     * Use this method when information changed on the server. Like updating the configuration
     */
    updateUserFromServer() {
        if(this.user?.id){
            users.refetch(this.user.id)
                .then((user) => {
                    this.user = user
                })
                .catch(error => {
                    console.error('Could not update user in store with data fetched from API')
                })
        }
    }

    setCustomer(customer: ICustomer) {
        this.customer = customer
    }

    setCustomers(newCustomers: ICustomer[]) {
        this.customers = newCustomers
    }

    setSelectedCustomer(customer: ICustomer) {
        this.selectedCustomer = customer
    }

    setApiUrl(apiUrl: string) {
        this.apiUrl = apiUrl
    }

    setBuyer(buyer: {name: string, email: string}) {
        this.buyer = buyer
    }

    setToken(token: string) {
        this.token = token
    }

    setIPDToken(token: string) {
        this.IPDToken = token
    }

    setUser(user: IUser|undefined) {
        this.user = user
    }

    setShowTrialInfo(enabled: boolean) {
        this.showTrialInfo = enabled
    }

    setDisplayArchived(displayArchived: boolean){
        this.displayArchived = displayArchived
    }

    setPageLength(pageLength: number){
        this.pageLength = pageLength
    }

    get loggedIn() {
        return this.user !== undefined
    }

    get isTrialUser() {
        return this.user?.type === 'trial'
    }

    get userPublicId() {
        return this.user
            ? this.user.public_id
            : ''
    }

    get authHeader() {
        return {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.token,
                'X-Impersonate': this.isImpersonating ? 1 : 0,
            }
        }
    }

    get isNotAdmin() {
        return ! this.isAdmin
    }

    get isAdmin() {
        return (this.user?.is_admin || false)
    }

    setUserLanguage(languageCode:string){
        this.languageSelected = languageCode;
    }

    /**
     * When we're impersonating, we want to become an other user.
     *
     * To do that:
     * - set aside the information of currently logged in user
     * - taken on the information of the user we want to become
     *
     * @param user
     * @param token
     */
    impersonate(user: IUser, token: string)
    {
        // can only impersonate when someone is logged in
        if(persistentStore.user === undefined){
            console.error('Cannot impersonate when no user is logged in')
            return
        }

        if(persistentStore.token === undefined){
            console.error('Cannot impersonate when no token is set')
            return
        }

        // backup the token and user information
        this.userWhoIsImpersonating = {
            user: persistentStore.user,
            token: persistentStore.token,
            customers: this.customers,
            selectedCustomer: this.selectedCustomer,
        }

        // 'login' as the other user
        persistentStore.user = user
        persistentStore.setToken(token)
        this.isImpersonating = true
        this.selectedCustomer = undefined
    }

    /**
     * Reset everything done in impersonate()
     */
    stopImpersonating(){
        if(this.userWhoIsImpersonating === undefined){
            console.error('Cannot stop impersonating as there is no info about the original user')
            return
        }

        // 'login' as yourself again
        persistentStore.user = this.userWhoIsImpersonating.user
        persistentStore.setToken(this.userWhoIsImpersonating.token)
        this.customers = this.userWhoIsImpersonating.customers
        this.selectedCustomer = this.userWhoIsImpersonating.selectedCustomer

        this.userWhoIsImpersonating = undefined
        this.isImpersonating = false
    }
}

decorate(PersistentStore, {
    IPDToken: observable,
    apiUrl: observable,
    buyer: observable,
    chosenCardType: observable,
    customer: observable,
    customerView: observable,
    customers: observable,
    displayArchived: observable,
    isImpersonating: observable,
    order: observable,
    selectedCustomer: observable,
    showTrialInfo: observable,
    token: observable,
    user: observable,

    authHeader: computed,
    isAdmin: computed,
    isNotAdmin: computed,
    isTrialUser: computed,
    loggedIn: computed,
    userPublicId: computed,

    emptyStore: action,
    impersonate: action,
    pageLength: observable,
    setApiUrl: action,
    setBuyer: action,
    setChosenCardType: action,
    setCustomer: action,
    setCustomerView: action,
    setCustomers: action,
    setIPDToken: action,
    setOrder: action,
    setPageLength: action,
    setSelectedCustomer: action,
    setShowTrialInfo: action,
    setToken: action,
    setUser: action,
    updateCustomer: action,
    updateCustomerFromServer: action,
})

const persistentStore = new PersistentStore()
export default persistentStore
