import React, {Suspense, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Switch, Route, Router, history} from './utils/router'
import axios from 'axios'
import * as serviceWorker from './serviceWorker';
import './css/dashboard.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactGA from 'react-ga'
import * as Sentry from "@sentry/react"
import {Integrations as TracingIntegrations} from "@sentry/tracing";

import Login from './pages/Users/Login'

import store from './stores/store'
import {users} from './api/users'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './utils/i18n'
import i18next from 'i18next'
import RequestPasswordReset from "./pages/Users/RequestResetPassword";
import ResetPassword from "./pages/Users/ResetPassword";
import {registerLocale} from "react-datepicker";
import nl from 'date-fns/locale/nl'
import Routes from "./Routes";
import persistentStore from "./stores/persistentStore";
import {useQuery} from "react-query";
import {info as infoApi} from "./api/info";
import {browserName, browserVersion, deviceType, getUA, osName, osVersion} from "react-device-detect";
import version from "./metadata.json";
import Loader from "./components/Loader";

const versionString = process.env.NODE_ENV === 'development'
    ? `${version.buildMajor}.${version.buildMinor}.DEV`
    : `${version.buildMajor}.${version.buildMinor}.${version.buildRevision}`

registerLocale('nl', nl)

if (process.env.REACT_APP_API_URL) {
    persistentStore.setApiUrl(process.env.REACT_APP_API_URL)
}

if (process.env.REACT_APP_IPD_TOKEN) {
    persistentStore.setIPDToken(process.env.REACT_APP_IPD_TOKEN)
}

if (process.env.REACT_APP_COMPANY) {
    store.setCompany(process.env.REACT_APP_COMPANY)
}

// @ts-ignore
if (window.sa_event) {
    // @ts-ignore
    store.setSaEvent(window.sa_event)
}

toast.configure({
    autoClose: 10000,
    draggable: false,
    position: toast.POSITION.TOP_RIGHT
})

const VisitorInfo = () => {
    // get info
    const infoQuery = useQuery(
        'info',
        infoApi.ip,
        {
            enabled: (persistentStore.browserInfo === undefined && persistentStore.IPDToken)
        }
    )

    useEffect(() => {
        if ( persistentStore.IPDToken && infoQuery.data && persistentStore.browserInfo === undefined) {
            const browserInfo: BrowserInfo = {
                user_agent: getUA,
                browser: browserName,
                browser_version: browserVersion,
                os: osName,
                os_version: osVersion,
                // @ts-ignore
                timezone: infoQuery.data.time_zone.name,
                // @ts-ignore
                local_time: infoQuery.data.time_zone.current_time,
                device: deviceType,
                // @ts-ignore
                ip: infoQuery.data.ip,
                // @ts-ignore
                threat: infoQuery.data.threat,
                // @ts-ignore
                city: infoQuery.data.city,
                // @ts-ignore
                country: infoQuery.data.country_name,
            }

            // console.log('Set the browserInfo!')
            // console.log(browserInfo)
            persistentStore.setBrowserInfo(browserInfo)
        }

    }, [infoQuery.data])

    // console.log(persistentStore.browserInfo)

    return <></>
}

// add client ip to axios headers
// DON'T DO THIS as this will cause CORS errors
//if(persistentStore.browserInfo?.ip){
//    console.log('setting ip in headers to: ' + persistentStore.browserInfo.ip)
//    axios.defaults.headers.common['CLIENT_IP'] = persistentStore.browserInfo.ip
//}

// setup code for Google Tag Manager
const gTag = document.createElement('script')
gTag.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src= \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f); })(window,document,\'script\',\'dataLayer\',\'GTM-N6BJNHD\');'
const gTagBody = document.createElement('noscript')
gTagBody.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N6BJNHD" height="0" width="0" style="display:none;visibility:hidden"></iframe>'

// set default language
i18next.changeLanguage(persistentStore.languageSelected)

// catch network errors
axios.defaults.timeout = 15000
axios.defaults.withCredentials = true
//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = persistentStore.apiUrl
if(persistentStore.token){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + persistentStore.token
}

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.error('interceptor error', error)
        let message = ''

        if (error.code === 'ECONNABORTED') {
            toast.error(error.message)
            return Promise.reject(error)
        }

        if (error.response !== undefined) {
            switch (error.response.status) {
                case 502:
                    message = 'System is starting up, please try again in a minute'
                    toast.error(message)
                    break
                case 401:
                    message = 'Login verlopen, log opnieuw in alstublieft'
                    // prevent too many messages
                    toast.clearWaitingQueue()
                    toast.error(message, {toastId: 401})

                    // remove locally saved items by logging out
                    users.logout()
                    history.push('/login')
                    break
                case 400:
                    toast.error(error.response.data.message)
                    break
                default:
                    break
            }
        }

        return Promise.reject(error)
    })

const apiUrl = process.env.REACT_APP_API_URL
if (apiUrl !== undefined) {
    persistentStore.setApiUrl(apiUrl)
}

// insert code for production
if (process.env.NODE_ENV === 'production') {
    // track and log errors with Sentry
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        environment: process.env.NODE_ENV,
        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        // @ts-ignore
        integrations: [new TracingIntegrations.BrowserTracing()],

        // To set a uniform sample rate
        tracesSampleRate: 0.2,
        release: 'strippenkaart-fe@' + versionString
    })

    if (persistentStore.user) {
        Sentry.setContext("user", {
            name: persistentStore.user.name,
            email: persistentStore.user.email
        });
    }

    // add Matomo analytics
    const matomoTag = document.createElement('script')
    matomoTag.innerHTML = "var _paq = window._paq = window._paq || [];\n" +
        "_paq.push(['trackPageView']);\n" +
        "_paq.push(['enableLinkTracking']);\n" +
        "(function() {\n" +
        "  var u=\"//analytics.cr.i2s.nl/\";\n" +
        "  _paq.push(['setTrackerUrl', u+'matomo.php']);\n" +
        "  _paq.push(['setSiteId', '2']);\n" +
        "  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n" +
        "  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n" +
        "})();"
    document.head.prepend(matomoTag)

    // // add SimpleAnalytics
    // const saTag = document.createElement('script')
    // saTag.defer = true
    // saTag.async = true
    // saTag.src = "https://scripts.simpleanalyticscdn.com/latest.js"

    // const saTagNoScript = document.createElement('noscript')
    // const saTagNoScriptImg = document.createElement('img')
    // saTagNoScriptImg.src = "https://queue.simpleanalyticscdn.com/noscript.gif"
    // saTagNoScriptImg.alt = "javascript is not supported"
    // saTagNoScript.appendChild(saTagNoScriptImg)

    // initialize Google Analytics
    ReactGA.initialize('UA-125586792-2')
    // automate the process of adding a pageview to Google Analytics

    history.listen(location => ReactGA.pageview(location.pathname + window.location.search))
    document.head.prepend(gTag)
    document.body.appendChild(gTagBody)
    // document.body.appendChild(saTag)
    // document.body.appendChild(saTagNoScript)

    // add FB Pixel when it's a trial account
    if (persistentStore.user?.type === 'trial') {
        const fbPixel = document.createElement('noscript')
        fbPixel.innerHTML = '<!-- Facebook Pixel Code -->\n' +
            '<script>\n' +
            '  !function(f,b,e,v,n,t,s)\n' +
            '  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
            '  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
            '  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\n' +
            '  n.queue=[];t=b.createElement(e);t.async=!0;\n' +
            '  t.src=v;s=b.getElementsByTagName(e)[0];\n' +
            '  s.parentNode.insertBefore(t,s)}(window, document,\'script\',\n' +
            '  \'https://connect.facebook.net/en_US/fbevents.js\');\n' +
            '  fbq(\'init\', \'357355062269042\');\n' +
            '  fbq(\'track\', \'PageView\');\n' +
            '</script>\n' +
            '<noscript><img height="1" width="1" style="display:none"\n' +
            '  src="https://www.facebook.com/tr?id=357355062269042&ev=PageView&noscript=1"\n' +
            '/></noscript>\n' +
            '<!-- End Facebook Pixel Code -->'
        document.head.prepend(fbPixel)
    }
}

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <VisitorInfo/>
        <Router>
            <Switch>
                <Route path='/login' component={Login}/>
                <Route exact path='/requestpasswordreset' component={RequestPasswordReset}/>
                <Route exact path='/resetpassword/:selector/:validator' component={ResetPassword}/>
                <Route component={Routes}/>
                <ToastContainer/>
            </Switch>
        </Router>
    </Suspense>,
    document.getElementById('root')
)

serviceWorker.register({
    onUpdate: (registration: any) => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event: any) => {
                if (event.target.state === "activated") {
                    if (
                        window.confirm("Een nieuwe versie van de Strippenkaart App is beschikbaar")
                    ) {
                        users.event('UPDATE_FRONTEND_APP')
                        if ('serviceWorker' in navigator) {
                            caches.keys().then(function (cacheNames) {
                                cacheNames.forEach(function (cacheName) {
                                    console.log(`Deleting cache: ${cacheName}`)
                                    caches.delete(cacheName);
                                });
                            });
                        }
                        window.location.reload()
                    }
                }
            })
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"})
        }
    },
})
