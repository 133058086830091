import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {trials} from "../../api/trials";
import {useRouter} from "../../utils/router";
import {info as infoApi} from "../../api/info";
import {useQuery} from "react-query";
import {clearCacheAsync} from "../../utils/clearCache";
import {browserName, browserVersion, deviceType, getUA, osName, osVersion} from "react-device-detect";

const TrialPage = () => {
    const {t} = useTranslation()
    const router = useRouter()
    const infoQuery = useQuery('info', () => process.env.NODE_ENV === 'production' ? infoApi.ip() : null)

    /** DEVELOPMENT **/
    // create the account
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            return
        }

        trials.createTrialAccount({ip: '127.0.0.1', userAgent: 'chrome-waarschijnlijk'})
            .then((data: any) => {
                window.location.href = window.location.origin + '/customers'
            })
    }, []);

    /** PRODUCTION **/
    useEffect(() => {
        // TODO filter out webcrawlers, spiders, Google, etc.
        if (process.env.NODE_ENV === 'development') {
            return
        }

        // remove all local cache and stuff
        clearCacheAsync()
            .then(function(){
                if (infoQuery.data) {
                    const browserInfo: BrowserInfo = {
                        user_agent: getUA,
                        browser: browserName,
                        browser_version: browserVersion,
                        os: osName,
                        os_version: osVersion,
                        // @ts-ignore
                        timezone: infoQuery.data.time_zone.name,
                        // @ts-ignore
                        local_time: infoQuery.data.time_zone.current_time,
                        device: deviceType,
                        // @ts-ignore
                        ip: infoQuery.data.ip,
                        // @ts-ignore
                        threat: infoQuery.data.threat,
                        // @ts-ignore
                        city: infoQuery.data.city,
                        // @ts-ignore
                        country: infoQuery.data.country_name,
                    }

                    trials.createTrialAccount(browserInfo)
                        .then(() => {
                            window.location.href = window.location.origin + '/customers'
                        })
                }
            })
    }, [infoQuery.data, router])

    return (
        <div>
            {t('Please wait for your account to be created')}
        </div>
    );
}

export default TrialPage;

