import React, {useState, useEffect} from 'react';
import {Button, Card, Spinner} from "react-bootstrap";
import {useLoading} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import persistentStore from "../../../stores/persistentStore";
import {teams} from '../../../api/teams';
import {ICompany} from '../../../interfaces/ICompany';

const InvoicesTab = () => {
    const [loading, startLoading, stopLoading] = useLoading()
    const [companyId, setCompanyId] = useState<number>(0)
    const [companyName, setCompanyName] = useState<string>('')
    const [companyAddress, setCompanyAddress] = useState<string>('')
    const [companyZipCode, setCompanyZipCode] = useState<string>('')
    const [companyCity, setCompanyCity] = useState<string>('')
    const [companyCOCNumber, setCompanyCOCNumber] = useState<string>('')
    const [companyVATNumber, setCompanyVATNumber] = useState<string>('')
    const {t} = useTranslation()

    useEffect(() => {
        if (!persistentStore?.user) {
            return
        }

        if (!persistentStore.user?.team_id) {
            return
        }

        teams.get(persistentStore.user.team_id)
            .then((data) => {
                if (data.success === false) {
                    console.error(data.data.message)
                    toast.error(data.data.message)
                } else {
                    const companyFromServer: ICompany = data.data.team;

                    setCompanyId(companyFromServer.id)
                    setCompanyName(companyFromServer.name)
                    setCompanyAddress(companyFromServer.address)
                    setCompanyZipCode(companyFromServer.zipcode)
                    setCompanyCity(companyFromServer.city)
                    setCompanyCOCNumber(companyFromServer.coc_number)
                    setCompanyVATNumber(companyFromServer.vat_number)
                }
            })
    }, [])

    const handleSubmitSettings = (event: any) => {
        event.preventDefault();

        const CompanyData: ICompany = {
            id: companyId,
            name: companyName,
            address: companyAddress,
            zipcode: companyZipCode,
            city: companyCity,
            coc_number: companyCOCNumber,
            vat_number: companyVATNumber,
            user_id: persistentStore?.user?.id
        }

        if (companyId && companyId > 0) {
            startLoading()
            teams.updateCompany(CompanyData)
                .then((data) => {

                    if (data.success === false) {
                        //console.error(data.data.message)
                        //toast.error(data.data.message)
                    } else {
                        toast.success(t('company details updated to your account'))
                        const updatedUser = Object.assign({}, persistentStore.user)
                        updatedUser.team_id = data.data.team.id
                        setCompanyId(data.data.team.id)
                        persistentStore.setUser(updatedUser)
                    }
                    stopLoading()
                })
        } else {
            startLoading()
            teams.addCompany(CompanyData)
                .then((data) => {
                    if (data.success === false) {
                        //console.error(data.data.message)
                        //toast.error(data.data.message)
                    } else {
                        toast.success(t('Information saved'))
                        const updatedUser = Object.assign({}, persistentStore.user)
                        updatedUser.team_id = data.company.id
                        persistentStore.setUser(updatedUser)
                        stopLoading()
                    }
                })
        }
    }

    return (
        <form onSubmit={(e) => handleSubmitSettings(e)}>
            <Card className="mt-4 mb-5 settings-page">
                <Card.Body>
                    <p className="mt-4"><b>{t('Invoice')}</b></p>
                    <p className="text-muted">{t('These changes reflect on invoices and pdf export')}</p>

                    <div className="form-group">
                        <p><b>{t('Company Name')}</b></p>
                        <label className="text-muted" htmlFor="inputCompanyName">
                            {t('The company name as it should appear in invoice')}.
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputCompanyName"
                            aria-describedby="nameHelp"
                            placeholder={t("Parts Unlimited")}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            name="companyName"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <p><b>{t('Company Address')}</b></p>
                        <label className="text-muted" htmlFor="inputAddress">
                            {t('The address as it should appear in invoice')}.
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputAddress"
                            aria-describedby="addressHelp"
                            placeholder={t("123 West Road Avenue")}
                            value={companyAddress}
                            onChange={(e) => setCompanyAddress(e.target.value)}
                            name="companyAddress"
                        />
                    </div>

                    <div className="form-group">
                        <p><b>{t('Zip Code')}</b></p>
                        <label className="text-muted" htmlFor="inputZipCode">
                            {t('The zip code as it should appear in invoice')}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputZipCode"
                            aria-describedby="zipCodeHelp"
                            placeholder={t("1234 AA")}
                            value={companyZipCode}
                            onChange={(e) => setCompanyZipCode(e.target.value)}
                            name="companyZipcode"
                        />
                    </div>

                    <div className="form-group">
                        <p><b>{t('City')}</b></p>
                        <label className="text-muted" htmlFor="inputCity">
                            {t('The city as it should appear in invoice')}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputCity"
                            aria-describedby="cityHelp"
                            placeholder={t("Amsterdam")}
                            value={companyCity ?? ''}
                            onChange={(e) => setCompanyCity(e.target.value)}
                            name="companyCity"
                        />
                    </div>

                    <div className="form-group">
                        <p><b>{t('Chamber Of Commerce number')}</b></p>
                        <label className="text-muted" htmlFor="inputCOCNumber">
                            {t('The chamber of commerce number for your company. It will be pull on the invoice')}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputCOCNumber"
                            aria-describedby="cocNumberHelp"
                            placeholder={t("Your chamber of commerce number")}
                            value={companyCOCNumber}
                            onChange={(e) => setCompanyCOCNumber(e.target.value)}
                            name="companyCOCNumber"
                        />
                    </div>

                    <div className="form-group">
                        <p><b>{t('VAT Number')}</b></p>
                        <label className="text-muted" htmlFor="inputVAT">
                            {t('If applicable, the VAT number')}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputVAT"
                            aria-describedby="VATHelp"
                            placeholder={t("Your company's VAT number")}
                            value={companyVATNumber}
                            onChange={(e) => setCompanyVATNumber(e.target.value)}
                            name="companyVAT"
                        />
                    </div>
                </Card.Body>

                <Card.Footer>
                    <Button
                        type="submit"
                        size='lg'
                        id='company-settings-update'
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && t('Submit')}
                    </Button>
                </Card.Footer>
            </Card>
        </form>
    )
}

export default InvoicesTab;
