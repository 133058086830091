import axios from 'axios'
import persistentStore from "../stores/persistentStore";

const trialRequests = () => {

    const create = async (props: any) => {
        const path = `/payments/trial`
        const data = props

        return axios.post(persistentStore.apiUrl + path, data, persistentStore.authHeader)
            .then(response => response.data)
            .catch(error => error)
    };

    const createTrialAccount = async (info: any) => {
        const path = `/trial`
        const data = info

        return axios.post(persistentStore.apiUrl+path, data, {})
            .then(response => {
                console.error('Trial request is back with response');
                console.log('response.data.data', response.data.data);

                if(response.data.data.user && response.data.data.token){
                    persistentStore.setUser(response.data.data.user)
                    persistentStore.setToken(response.data.data.token)
                    persistentStore.setShowTrialInfo(true)

                    return response.data
                }

                throw new Error('Could not create trial account')
            })
    };

    return {
        create,
        createTrialAccount
    }
}

export const trials = trialRequests()
