import React from "react";
import {useTranslation} from "react-i18next";
import {ICustomer} from "../../interfaces/ICustomer";

type ProgressBarType = {
    customer: ICustomer,
}

const ProgressBar = (props: ProgressBarType) => {
    const customer = props.customer;
    const stripsBought = customer.stats.total_amount_on_active_cards;
    const percentageUsed = customer.stats.percentage_used;

    const {t} = useTranslation()
    let backgroundColor = 'green';

    switch (true) {
        case percentageUsed < 50:
            backgroundColor = 'green';
            break;
        case percentageUsed < 75:
            backgroundColor = '#FA29F9';
            break;
        case percentageUsed < 95:
            backgroundColor = '#ED0255';
            break;
        default:
            backgroundColor = 'red';
            break;
    }

    // the text to show in the progressbar
    let barText = '';
    if (percentageUsed > 50) {
        barText = percentageUsed + '% ' + t('used');
    }

    const progressStyle = {
        width: percentageUsed + "%",
        "backgroundColor": backgroundColor
    };


    return (
        <div className="progress w-100">
            <div className="progress-bar"
                 role="progressbar"
                 style={progressStyle}
                 aria-valuenow={percentageUsed}
                 aria-valuemin={0}
                 aria-valuemax={stripsBought}
            >
                {barText}
            </div>
        </div>
    )
}

export default ProgressBar
