import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Card, Spinner, Image, Row, Col, Alert} from "react-bootstrap";
import store from "../../../stores/store";
import {users} from "../../../api/users";
import {toast} from "react-toastify";
import {useLoading} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";
import {useDropzone} from 'react-dropzone'
import {Image as ImageIcon} from 'react-feather'
import persistentStore from "../../../stores/persistentStore";
import {clearCache} from "../../../utils/clearCache";
import {useRouter} from "../../../utils/router";
import ChangeColor from './ChangeColor';
import featureToggleStore from "../../../stores/featureToggleStore";

const GeneralTab = () => {
    const [name, setName] = useState(persistentStore.user?.name || '')
    const [email, setEmail] = useState(persistentStore.user?.email || '')
    const [selectedFile, setSelectedFile] = useState('')
    const [selectedFileName, setSelectedFileName] = useState('')
    const [selectedFileNamePreview, setSelectedFileNamePreview] = useState('')
    const [userLogo, setUserLogo] = useState('')
    const [clearLogo, setClearLogo] = useState<boolean>(false)
    const [uploadError, setUploadError] = useState<string | undefined>()
    const [newPassword, setNewPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')
    const [loading, startLoading, stopLoading] = useLoading()
    const {t} = useTranslation()
    const router = useRouter()
    const inputEmail = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (persistentStore.user) {
            setUserLogo(persistentStore.user.logo || '')
        }

        if (inputEmail.current) {
            inputEmail.current.focus();
        }
    }, [router.query.setLoginInfo])

    // when the form is submitted
    const handleSubmit = (event: any) => {
        event.preventDefault()

        if (!persistentStore.user?.id) {
            return
        }

        startLoading()
        const updatedUser = Object.assign(
            {name: name, email: email}
        )

        users.update(updatedUser, persistentStore.user.id)
            .then(response => {
                toast.success(t('Settings updated'))
            })

            /** LOGO STUFF **/
            .then(() => {
                // update logo, when changed
                if (typeof selectedFile === 'object') {
                    uploadLogo()
                }

                if (clearLogo) {
                    users.clearLogo()
                }

            })

            /** CLEANUP **/
            .finally(() => {
                removeFile()
                stopLoading()
            })
            .catch((error) => {
                const response = error.response
                const detail = response.data.errors.detail
                const title = response.data.errors.title

                if (detail.includes('UNIQUE_EMAIL')) {
                    toast.error('Dit emailadres is al in gebruik bij een ander account')
                    return
                }

                toast.error(title + ': ' + detail)
            })
    }

    const handlePasswordChange = (event: any) => {
        event.preventDefault()
        startLoading()
        setError('')

        if (newPassword !== rePassword) {
            setError(t("Re-enter password doesn't match"))
            stopLoading()
            return
        }

        const changePassword = Object.assign(
            {newPassword: newPassword, rePassword: rePassword}
        )

        const handleError = (response: any) => {

            if(response.data.errors?.newPassword) {
                const errorMessage = response.data.errors.newPassword.join(', ')
                setError(errorMessage)
                stopLoading()
                return
            }

            if (response.data.message) {
                console.error(response.data.message)
            }

            if (response.status === 404) {
                setError(t('There is no account with this email address'))
                stopLoading()
                return
            }

            if (response.status >= 500) {
                setError(t('Er is een fout opgetreden'))
                stopLoading()
                return
            }
            stopLoading()
            setError(t('Sorry, an error occurred'))
        }

        users.changePassword(changePassword)
            .then((response: any) => {
                if (response.success !== true) {
                    return handleError(response.response)
                }
                setNewPassword('')
                setRePassword('')
                setSuccess(t('Success! Password changed successfully'))
            })
            .finally(() => {
                stopLoading()
            })
    }

    const uploadLogo = () => {
        const formData = new FormData();
        formData.append(
            "logo",
            selectedFile,
            selectedFileName
        );

        users.updateLogo(formData)
            .then((response: any) => {
                if (response.success) {
                    setSelectedFile('')
                    setUserLogo(response.data.user.logo)
                }
            })
            .catch((error) => {
                toast.error(t('Could not update logo'))
            })
    }

    const onDrop = useCallback(acceptedFiles => {
        // clear any upload errors
        setUploadError(undefined)

        const fileToUpload = acceptedFiles[0]
        if (!fileToUpload) {
            return
        }

        const sizeInMB: number = fileToUpload.size / 1000000;
        const fileTooLarge = sizeInMB > store.logoUploadLimit

        if (fileTooLarge) {
            const errorMessage: string = t('This file is over the maximum file limit of')
                + ' ' + store.logoUploadLimit + 'MB '
                + `): ${sizeInMB.toFixed(2)}M. ` +
                t('Please select a smaller image and try again')
            // get the file size in standard format
            setUploadError(errorMessage)
            return
        }

        setSelectedFileName(fileToUpload.name)
        setSelectedFile(fileToUpload)
        setSelectedFileNamePreview(URL.createObjectURL(fileToUpload))
        // eslint-disable-next-line
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, image/gif, image/bmp'
    });

    const removeFile = () => {
        setSelectedFileName('')
        setSelectedFile('')
        setSelectedFileNamePreview('')
    }

    const clearLogoLocally = () => {
        setClearLogo(true)
        setUserLogo('')
        setSelectedFileName('')
        setSelectedFile('')
        setSelectedFileNamePreview('')
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Card className='mt-4 mb-5'>
                    <Card.Body>
                        <div className="form-group">
                            <h4>{t('Name')}</h4>
                            <label
                                htmlFor="inputName">{t('Your name, as how your customers will see it')}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputName"
                                aria-describedby="nameHelp"
                                placeholder="Your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                                required
                            />
                            <p>{t('When you send emails to your customers, this name will appear at the bottom of that mail')}</p>
                        </div>

                        <div className="form-group">
                            <h4>{t('Email')}</h4>
                            <label htmlFor="inputName">
                                {t('Set or change your email address')}
                            </label>
                            <input
                                type="text"
                                ref={inputEmail}
                                className="form-control"
                                id="inputEmail"
                                aria-describedby="emailHelp"
                                placeholder={t('Your email address')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                            />
                            <p>{t('When you set your email address, you can login on other devices and use the information you have store here')}</p>
                        </div>

                        <div className="form-group h-100">
                            <h4>{t('Upload your logo')}</h4>
                            <div className='mb-2'>
                                {t('Your logo will be used on the customer page and as your avatar')}
                            </div>
                            {uploadError && <Alert variant='danger'>{uploadError}</Alert>}
                            <Row className="col-md-12">
                                <Col>
                                    {selectedFile && (
                                        <>
                                            <Image
                                                thumbnail
                                                width={150}
                                                alt={selectedFileName}
                                                src={selectedFileNamePreview}
                                            />
                                            <span className="btn text-danger" onClick={removeFile}>
                                            {t('Remove')}
                                        </span>
                                        </>
                                    )}

                                    {userLogo && !selectedFile && (
                                        <>
                                            <Image
                                                thumbnail
                                                width={150}
                                                alt={persistentStore.user?.name}
                                                src={`${persistentStore.apiUrl}/img/logo/${userLogo}`}
                                            />
                                            <span className="btn text-danger" onClick={clearLogoLocally}>
                                            {t('Remove')}
                                        </span>
                                        </>
                                    )}

                                    {!userLogo && !selectedFile && (
                                        <div
                                            className='h-100 justify-content-center align-items-center row'
                                            style={{backgroundColor: 'lightgray'}}
                                        >
                                            <ImageIcon size={100} color={'gray'} className='col-12'/>
                                        </div>
                                    )}

                                </Col>

                                <Col>
                                    <div className="form-control" {...getRootProps()} style={{minHeight: "150px"}}>
                                        <input {...getInputProps()} />
                                        {isDragActive ?
                                            <p>{t('Drop them here')}...</p> :
                                            <p>{t('Drag your logo here to upload them')}</p>
                                        }
                                    </div>
                                    {isDragReject && (
                                        <p>{t('This file extension is not allowed')}.</p>
                                    )}
                                </Col>
                            </Row>
                        </div>

                    </Card.Body>

                    <Card.Footer>
                        <Button
                            type="submit"
                            size='lg'
                            id='name-update'
                            variant="primary"
                            disabled={loading}
                        >
                            {loading &&
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                            {!loading && t('Submit')}
                        </Button>
                    </Card.Footer>
                </Card>
            </form>

            <form className="mb-5" onSubmit={handlePasswordChange}>
                <Card>
                    <Card.Body>
                        <div className="form-group">
                            <h4>{t('Change Password')}</h4>
                            <p>
                                {t('Leave empty when you don\'t want to change your password')}.<br/>
                            </p>
                        </div>
                        <Row className="col-md-12 p-0">
                            <Col md={12}>
                                {error && <Alert variant='danger'>{error}</Alert>}
                                {success && <Alert variant={"success"}>{success}</Alert>}
                            </Col>
                            <Col md={3}>
                                <div className="form-group">
                                    <label htmlFor="inputNewPassword" className="sr-only">
                                        {t('New password')}
                                    </label>
                                    <input type="password" id="inputNewPassword" className="form-control"
                                           placeholder={t('New password')}
                                           name="new_password" value={newPassword}
                                           onChange={e => setNewPassword(e.target.value)} disabled={loading}
                                           required
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="form-group">
                                    <label htmlFor="inputRePassword" className="sr-only">
                                        {t('Re-Enter password')}
                                    </label>
                                    <input type="password" id="inputRePassword" className="form-control"
                                           placeholder={t('Re-Enter password')}
                                           name="re_enter_password" value={rePassword}
                                           onChange={e => setRePassword(e.target.value)} disabled={loading}
                                           required
                                    />
                                </div>
                            </Col>
                            <Col md={3}>
                                <Button id='btn-change-password' variant='primary' type="submit" disabled={loading}>
                                    {loading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                    {!loading && t('Change Password')}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </form>

            {featureToggleStore.themeColors && (
                <ChangeColor/>
            )}

            <Card>
                <Card.Body>
                    <div className="form-group">
                        <h4>{t('Cache')}</h4>
                        <div className='mb-2 mt-2'>
                            <p>
                                {t('Delete your local cache when the app is not behaving as expected')}.<br/>
                                {t('The cache will be removed and this page will be reloaded')}.
                            </p>
                            <Row className="col-md-12">
                                <Button
                                    size='lg'
                                    id='btn-clear-cache'
                                    variant="outline-secondary"
                                    disabled={loading}
                                    onClick={() => {
                                        clearCache()
                                    }}
                                >
                                    {t('Clear cache')}
                                </Button>
                            </Row>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default GeneralTab;
