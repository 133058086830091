import Spinner from 'react-feather/dist/icons/settings';
import React, {useEffect, useState} from 'react'
import {Card} from "react-bootstrap";
import {Bar} from 'react-chartjs-2';
import {IChartDataset} from "../../interfaces/IChartDataset";
import {IChartData} from "../../interfaces/IChartData";
import {useQuery} from "react-query";
import {stats} from "../../api/stats";
import uiStore from "../../stores/uiStore";
import store from "../../stores/store";

type Props = {
    title: string
}

export const GraphRevenueOverTime = (props: Props) => {
    const {title} = props
    const [labels, setLabels] = useState<string[]>([])
    const [costs, setCosts] = useState<number[]>([])
    const [revenue, setRevenue] = useState<number[]>([])
    const [result, setResult] = useState<number[]>([])
    const statsQuery = useQuery(
        ['stats', 'revenue'],
        () => stats.revenue()
    )

    useEffect(() => {
        if (statsQuery.data) {
            setCosts(Object.values(statsQuery.data.costs) as number[])
            setRevenue(Object.values(statsQuery.data.revenue) as number[])
            setResult(Object.values(statsQuery.data.result) as number[])
            setLabels(statsQuery.data.labels)
        }
    }, [statsQuery.data])

    // from: http://www.menucool.com/rgba-color-picker
    const colors = {
        pink: 'rgba(255,204,255,1)',
        pinkOpacity: 'rgba(255,204,255,0.4)',
        blue: 'rgba(102,153,255,1)',
        blueOpacity: 'rgba(102,153,255,0.4)',
        green: 'rgba(102,204,153,1)',
        greenOpacity: 'rgba(102,204,153,0.4)',
        red: 'rgba(255,51,0,1)',
        redOpacity: 'rgba(255,51,0,0.4)',
        gold: 'rgba(255,204,0,1)',
        goldOpacity: 'rgba(255,204,0,0.4)',
    }

    const defaultChartDataSet: IChartDataset = {
        label: 'change me',
        backgroundColor: 'rgba(0,123,255,0.4)',
        borderCapStyle: 'butt',
        borderColor: 'rgba(0,123,255,1)',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        data: [],
        fill: true,
        lineTension: 0.1,
        pointBackgroundColor: '#fff',
        pointBorderColor: 'rgba(0,123,255,1)',
        pointBorderWidth: 1,
        pointHitRadius: 10,
        pointHoverBackgroundColor: 'rgba(0,123,255,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        pointRadius: 1,
    };

    const revenueDataSet: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'revenue',
        backgroundColor: colors.greenOpacity,
        borderColor: colors.green,
        data: revenue || {},
    })

    const costsDataSet: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'costs',
        backgroundColor: colors.redOpacity,
        borderColor: colors.red,
        data: costs || {},
    })

    const resultDataSet: IChartDataset = Object.assign({}, defaultChartDataSet, {
        label: 'result',
        type: 'bar',
        fill: false,
        backgroundColor: colors.goldOpacity,
        borderColor: colors.gold,
        data: result || {},
    })

    const chartData: IChartData = {
        // should be: dec, jan, feb, ...
        labels: labels,
        datasets: [revenueDataSet, costsDataSet, resultDataSet]
    }

    const options = {
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: any) => {
                    const amount: number = parseInt(tooltipItem.value)
                    return `${(amount).toLocaleString(store.locale, uiStore.moneyNoDecimals)}`
                }
            }
        }
    }

    return (
        <Card>
            <Card.Header as="h5">{title}</Card.Header>
            <Card.Body>
                <Bar data={chartData} options={options}/>
                {statsQuery.isLoading && <h1 className="text-center"><Spinner className="spinner"/></h1>}
            </Card.Body>
        </Card>
    )
}
