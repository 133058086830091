import React, {useState} from "react";
import {Button, Spinner, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ICompany} from "../../interfaces/ICompany";

type CustomerDetailsModalProps = {
    show: boolean,
    hide: () => void,
    companyData?: ICompany,
    handleSubmitSettings: (updatedCompany: ICompany) => void
}

const CustomerDetailsModal = (props: CustomerDetailsModalProps) => {
    const {hide, show, companyData, handleSubmitSettings} = props
    const {t} = useTranslation()
    const [companyName, setCompanyName] = useState<string>(companyData?.name || '')
    const [companyAddress, setCompanyAddress] = useState<string>(companyData?.address || '')
    const [companyZipCode, setCompanyZipCode] = useState<string>(companyData?.zipcode || '')
    const [companyCity, setCompanyCity] = useState<string>(companyData?.city || '')
    const [companyCOCNumber, setCompanyCOCNumber] = useState<string>(companyData?.coc_number || '')
    const [companyVATNumber, setCompanyVATNumber] = useState<string>(companyData?.vat_number || '')
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const updatedCompany: ICompany = Object.assign(
            companyData || { id: 0 },
            {
                address: companyAddress,
                name: companyName,
                zipcode: companyZipCode,
                city: companyCity,
                coc_number: companyCOCNumber,
                vat_number: companyVATNumber,
            }
        );

        try {
            await handleSubmitSettings(updatedCompany);
        } catch (error) {
            console.error("Error updating company:", error);
            // Handle error (e.g., show error message to user)
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={hide}>

            <Modal.Body>
                <div className="form-group">
                    <p><b>{t('Company Name')}</b></p>
                    <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        aria-describedby="nameHelp"
                        placeholder={t("Parts Unlimited")}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        name="companyName"
                        required
                    />
                </div>

                <div className="form-group">
                    <p><b>{t('Company Address')}</b></p>
                    <input
                        type="text"
                        className="form-control"
                        id="inputAddress"
                        aria-describedby="addressHelp"
                        placeholder={t("123 West Road Avenue")}
                        value={companyAddress}
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        name="companyAddress"
                        required
                    />
                </div>

                <div className="form-group">
                    <p><b>{t('Zip Code')}</b></p>
                    <input
                        type="text"
                        className="form-control"
                        id="inputZipCode"
                        aria-describedby="zipCodeHelp"
                        placeholder={t("1234 AA")}
                        value={companyZipCode}
                        onChange={(e) => setCompanyZipCode(e.target.value)}
                        name="companyZipcode"
                        required
                    />
                </div>

                <div className="form-group">
                    <p><b>{t('City')}</b></p>
                    <input
                        type="text"
                        className="form-control"
                        id="inputCity"
                        aria-describedby="cityHelp"
                        placeholder={t("Amsterdam")}
                        value={companyCity}
                        onChange={(e) => setCompanyCity(e.target.value)}
                        name="companyCity"
                        required
                    />
                </div>

                <div className="form-group">
                    <p><b>{t('Chamber Of Commerce number')}</b></p>
                    <input
                        type="text"
                        className="form-control"
                        id="inputCOCNumber"
                        aria-describedby="cocNumberHelp"
                        placeholder={t("12345678")}
                        value={companyCOCNumber}
                        onChange={(e) => setCompanyCOCNumber(e.target.value)}
                        name="companyCOCNumber"
                        required
                    />
                </div>

                <div className="form-group">
                    <p><b>{t('VAT Number')}</b></p>
                    <input
                        type="text"
                        className="form-control"
                        id="inputVAT"
                        aria-describedby="VATHelp"
                        placeholder={t("NL001234567B01")}
                        value={companyVATNumber}
                        onChange={(e) => setCompanyVATNumber(e.target.value)}
                        name="companyVAT"
                        required
                    />
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={hide} disabled={loading}>
                    {t('Cancel')}
                </Button>
                <Button
                    type="submit"
                    size='lg'
                    id='settings-update'
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        t('Submit')
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CustomerDetailsModal;
