import React, {useEffect, useState} from "react";
import "./OrderProcessing.scss"
import {useTranslation} from "react-i18next";
import {useRouter} from "../../utils/router";
import {Image, Col, Row, Container} from 'react-bootstrap'
import {orders} from "../../api/orders";
import {IOrder} from "../../interfaces/IOrder";
import Button from "react-bootstrap/Button";
import persistentStore from "../../stores/persistentStore";
import {useQuery} from "react-query";

const OrderProcessing = () => {
    const router = useRouter()
    const orderId: string = router.query.order_public_id
    const [order, setOrder] = useState<IOrder>({} as IOrder)
    const [count, setCount] = useState(0)
    const orderQuery = useQuery(
        ['order', orderId],
        () => orders.get(orderId),
        {
            enabled: (orderId !== undefined) && (order.status !== 'paid') && (count < 30),
            refetchInterval: 2500,
        }
    )

    const {t} = useTranslation();

    useEffect(() => {
        if (orderQuery.data) {
            setCount((previous: number) => previous + 1)
            setOrder(orderQuery.data)

            if (orderQuery.data.status === 'open') {
                // when the order is still open, return the user to the order page
                // this happens when the payment was cancelled by the user
                // window.location.replace(`/order/${orderQuery.data.seller_id}`)
                // console.log('Je wordt teruggestuurd')
            } else {
                // when order is closed, remove the stored order from the store
                persistentStore.setChosenCardType(undefined)
                persistentStore.setOrder(undefined)
            }
        }

        // disable, otherwise `order` needs to be in there and that causes an infinite loop
        // eslint-disable-next-line
    }, [orderQuery, orderId])

    const HeaderSection = () => {
        let logo = <h2>{order?.seller?.name}</h2>
        if (order?.seller?.logo) {
            logo =
                <Image
                    src={`${persistentStore.apiUrl}/img/logo/${order?.seller?.logo}`}
                    alt='logo'
                    style={{maxWidth: '250px'}}
                    className='col-6'
                />
        }
        return (logo);
    }

    if (orderQuery.data?.status === 'paid') {
        // clear the variables that were set in the order process
        // persistentStore.setBuyer({name: '', email: ''})
        persistentStore.setOrder(undefined)
        persistentStore.setChosenCardType(undefined)
    }

    return (
        <div>
            <Container className="mt-5 pt-5">
                <Row className="block-row pb-5">
                    <Col md={12} className="text-center mt-5">
                        <HeaderSection/>
                    </Col>

                    {order.status === 'paid' && (
                        <Col md={12} className="text-center pt-5">
                            <p>{t("Thank you!")}</p>
                            <p>{t("The invoice for your order has been sent by email")}. {t('Please check your spambox if you have not received the email within 5 minutes')}</p>
                            <p>{t(`If you have any questions about this order, send an email to {{sellerName}} at {{sellerMail}}`, {
                                sellerName: order?.seller?.name,
                                sellerMail: order?.seller?.email
                            })}</p>
                        </Col>
                    )}

                    {order.status === 'open' && (
                        <Col md={12} className="text-center pt-5">
                            <p>{t("The order is processing")}</p>
                            <p>{t("Current status")}: {order.status}</p>
                        </Col>
                    )}

                    {orderQuery.isLoading && (
                        <>
                            <span className="sr-only">Controleren op status...</span>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only">Controleren op status...</span>
                            </div>
                        </>
                    )}
                </Row>
                {(order?.buyer?.public_id) && (
                    <Row>
                        <Button
                            onClick={() => router.push(`/status/${order?.buyer?.public_id}`)}
                        >
                            {t('To my personal page')}
                        </Button>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default OrderProcessing;
