import {ICustomer} from "../../interfaces/ICustomer";
import React from "react";

export const CustomerProgressBar = (props: {customer: ICustomer}) => {
    const customer = props.customer;
    const stripsBought = customer.stats.total_amount_on_active_cards;
    const stripsRemaining = customer.stats.total_strips_available;

    const percentageAvailable = stripsBought < 1 ? 0 : (100 / stripsBought) * stripsRemaining;
    const percentageUsed = stripsBought < 1
        ? 0
        : (100 - percentageAvailable) > 100
            // little FIX: when percentage says 100% but there are strips left, make
            // it 99%
            ? stripsRemaining > 0 ? 99 : 100
            : Math.floor(100 - percentageAvailable);
    let backgroundColor = 'green';

    switch (true) {
        case percentageUsed < 50:
            backgroundColor = 'green';
            break;
        case percentageUsed < 75:
            backgroundColor = '#FA29F9';
            break;
        case percentageUsed < 95:
            backgroundColor = '#ED0255';
            break;
        default:
            backgroundColor = 'red';
            break;
    }

    // the text to show in the progressbar
    let barText = percentageUsed + '% gebruikt';

    const progressStyle = {
        width: percentageUsed + "%",
        "backgroundColor": backgroundColor
    };

    return (
        <div className="progress">
            <div className="progress-bar p-2"
                 role="progressbar"
                 style={progressStyle}
                 aria-valuenow={percentageUsed}
                 aria-valuemin={0}
                 aria-valuemax={stripsBought}
            >
                {barText}
            </div>
        </div>
    )
}
