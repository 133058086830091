import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Card, Row, Col, Button, Spinner, Form} from "react-bootstrap";
import {users} from "../../../api/users";
import {useLoading} from "../../../utils/helpers";
import {toast} from "react-toastify";
import {IUserSettings} from "../../../interfaces/IUserSettings";
import persistentStore from "../../../stores/persistentStore";
import {captureException} from "@sentry/react";

const CommunicationTab = () => {
    const {t} = useTranslation()
    const [loading, startLoading, stopLoading] = useLoading()
    const [emailTemplateBookOffCredits, setEmailTemplateBookOffCredits] = useState<string>('')
    const [previewText, setPreviewText] = useState<string>('')
    const [emailCardLeft, setEmailCardLeft] = useState<number>(0)
    const [emailCardTemplate, setEmailCardTemplate] = useState<string>('')
    const [emailAddNewCardTemplate, setEmailAddNewCardTemplate] = useState<string>('')
    const [previewCardText, setPreviewCardText] = useState<string>('')
    const [previewAddNewCardText, setPreviewAddNewCardText] = useState<string>('')
    const [willSendMailOnLowStrips, setWillSendMailOnLowStrips] = useState<boolean|undefined>(persistentStore.user?.settings?.emailWhenStripsLow)
    const [willSendMailNewCardMade, setWillSendMailNewCardMade] = useState<boolean|undefined>(persistentStore.user?.settings?.emailWhenNewCardMade)
    const [emailCustomerSubject, setEmailCustomerSubject] = useState<string>(persistentStore.user?.settings?.emailCustomerSubject || '')
    const [emailCardLeftSubject, setEmailCardLeftSubject] = useState<string>(persistentStore.user?.settings?.emailCardLeftSubject || '')
    const [emailNewCardAddSubject, setEmailNewCardAddSubject] = useState<string>(persistentStore.user?.settings?.emailNewCardAddSubject || '')
    const [previewCustomerSubjectext, setPreviewCustomerSubjectext] = useState<string>('')
    const [previewCardLeftSubjectext, setPreviewCardLeftSubjectext] = useState<string>('')
    const [previewNewCardAddSubjectext, setPreviewNewCardAddSubjectext] = useState<string>('')
    const currentUrl = window.location.origin
    const currentDate = new Date()
    const emailCardLeftValue = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const emailCardTimeLeftValue = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]
    const useTime = persistentStore.user?.settings.useType === 'hours'

    useEffect(() => {
        if(persistentStore.user){
            setEmailCardLeft(persistentStore.user.settings.emailCardLeft)
        }
    }, [])

    const testCustomer: any = {
        cards: [],
        email: 'test@strippenkaart.app',
        name: 'Paul Edenburg',
        strips_booked: 10,
        total_time_in_seconds_on_active_cards: 50,
        strips_expired: 0,
        total_strips_available: 40,
        description: 'proefles meegedraaid',
        booked_date: currentDate.getDate() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getFullYear(),
        customer_link: `<a href="${window.location.href}">${currentUrl}/status/1234abc</a>`,
        salespage_link: `<a href="${window.location.href}">${currentUrl}/order/${persistentStore.user?.slug}</a>`,
    }

    if (persistentStore.user?.settings.useType === 'hours') {
        testCustomer.strips_booked = '01:30'
        testCustomer.total_amount_on_active_cards = '50:00'
        testCustomer.total_strips_available = '48:30'
    }

    const defaultTemplateTextDutch = 'Hoi [name],\n' +
        '\n' +
        'Je strippenkaart is bijgewerkt.\n' +
        '\n' +
        'Aantal strippen afgeschreven: <strong>[strips_booked]</strong>\n' +
        'Totaal beschikbare strippen nu: <strong>[strips_remaining]</strong>\n' +
        'Afgeschreven op: <strong>[booked_date]</strong>\n' +
        '\n' +
        'Bekijk de huidige status op je persoonlijke pagina op de Strippenkaart App: [customer_link]\n' +
        '\n' +
        'TIP: sla bovenstaande link op in je favorieten, zo kun je altijd makkelijk en snel de huidige stand van je strippenkaart inzien!\n' +
        '\n' +
        'Veel succes!\n' +
        '\n' +
        persistentStore.user?.name

    const defaultCardTemplateTextDutch = 'Hoi [name],\n' +
        '\n' +
        'Je strippenkaart is bijgewerkt.\n' +
        '\n' +
        'Totaal beschikbare strippen nu: <strong>[strips_remaining]</strong>\n' +
        '\n' +
        'Bekijk de huidige status op je persoonlijke pagina op de Strippenkaart App: [customer_link]\n' +
        '\n' +
        'TIP: sla bovenstaande link op in je favorieten, zo kun je altijd makkelijk en snel de huidige stand van je strippenkaart inzien!\n' +
        '\n' +
        'Veel succes!\n' +
        '\n' +
        persistentStore.user?.name

    const defaultNewCardAddTemplateTextDutch = 'Hoi [name],\n' +
        '\n' +
        'Je strippenkaart is bijgewerkt.\n' +
        '\n' +
        'Bekijk de huidige status op je persoonlijke pagina op de Strippenkaart App: [customer_link]\n' +
        '\n' +
        'TIP: sla bovenstaande link op in je favorieten, zo kun je altijd makkelijk en snel de huidige stand van je strippenkaart inzien!\n' +
        '\n' +
        'Veel succes!\n' +
        '\n' +
        persistentStore.user?.name

    const updateEmailTemplate = (emailText: string, emailCardText: string, emailAddNewCardText: string) => {
        setEmailTemplateBookOffCredits(
            emailText
                .replace(/<br \/>\n/g, '\n')
                .replace(/<br \/>/g, '\n')
        )
        setEmailCardTemplate(
            emailCardText
                .replace(/<br \/>\n/g, '\n')
                .replace(/<br \/>/g, '\n')
        )

        setEmailAddNewCardTemplate(
            emailAddNewCardText
                .replace(/<br \/>\n/g, '\n')
                .replace(/<br \/>/g, '\n')
        )
    }

    useEffect(() => {
        const emailTemplate = persistentStore.user?.settings?.emailTemplate
            ? persistentStore.user?.settings?.emailTemplate
            : defaultTemplateTextDutch

        const emailCardTemplate = persistentStore.user?.settings?.emailCardTemplate
            ? persistentStore.user?.settings?.emailCardTemplate
            : defaultCardTemplateTextDutch

        const emailNewCardAddTemplate = persistentStore.user?.settings?.emailAddNewCardTemplate
            ? persistentStore.user?.settings?.emailAddNewCardTemplate
            : defaultNewCardAddTemplateTextDutch

        updateEmailTemplate(emailTemplate, emailCardTemplate, emailNewCardAddTemplate)

    }, [defaultTemplateTextDutch, defaultCardTemplateTextDutch, defaultNewCardAddTemplateTextDutch])

    useEffect(() => {
        // substitute all placeholders with the test-values
        const substitutedText = emailTemplateBookOffCredits
            .replaceAll('[name]', testCustomer.name)
            .replaceAll('[description]', testCustomer.description)
            .replaceAll('[strips_booked]', testCustomer.strips_booked)
            .replaceAll('[strips_remaining]', testCustomer.total_strips_available)
            .replaceAll('[booked_date]', testCustomer.booked_date)
            .replaceAll('[customer_link]', testCustomer.customer_link)
            .replaceAll('[salespage_link]', testCustomer.salespage_link)
            // have newlines
            .replace(/\n/g, '<br />')

        setPreviewText(substitutedText)

        const substitutedCardText = emailCardTemplate
            .replaceAll('[name]', testCustomer.name)
            .replaceAll('[description]', testCustomer.description)
            .replaceAll('[strips_booked]', testCustomer.strips_booked)
            .replaceAll('[strips_remaining]', testCustomer.total_strips_available)
            .replaceAll('[booked_date]', testCustomer.booked_date)
            .replaceAll('[customer_link]', testCustomer.customer_link)
            .replaceAll('[salespage_link]', testCustomer.salespage_link)
            // have newlines
            .replace(/\n/g, '<br />')

        setPreviewCardText(substitutedCardText)

        const substitutedAddNewCardText = emailAddNewCardTemplate
            .replaceAll('[name]', testCustomer.name)
            .replaceAll('[strips_remaining]', testCustomer.total_strips_available)
            .replaceAll('[customer_link]', testCustomer.customer_link)
            .replaceAll('[salespage_link]', testCustomer.salespage_link)
            // have newlines
            .replace(/\n/g, '<br />')

        setPreviewAddNewCardText(substitutedAddNewCardText)

        const substitutedSubjectext = emailCustomerSubject
            .replaceAll('[name]', testCustomer.name)
            .replaceAll('[description]', testCustomer.description)
            .replaceAll('[strips_booked]', testCustomer.strips_booked)
            .replaceAll('[strips_remaining]', testCustomer.total_strips_available)
            .replaceAll('[booked_date]', testCustomer.booked_date)
            .replaceAll('[customer_link]', testCustomer.customer_link)
            // have newlines
            .replace(/\n/g, '<br />')

        setPreviewCustomerSubjectext(substitutedSubjectext)

        const substitutedCardLeftSubjectext = emailCardLeftSubject
            .replaceAll('[name]', testCustomer.name)
            .replaceAll('[description]', testCustomer.description)
            .replaceAll('[strips_booked]', testCustomer.strips_booked)
            .replaceAll('[strips_remaining]', testCustomer.total_strips_available)
            .replaceAll('[booked_date]', testCustomer.booked_date)
            .replaceAll('[customer_link]', testCustomer.customer_link)
            // have newlines
            .replace(/\n/g, '<br />')

        setPreviewCardLeftSubjectext(substitutedCardLeftSubjectext)

        const substitutedNewCardAddSubjectext = emailNewCardAddSubject
            .replaceAll('[name]', testCustomer.name)
            .replaceAll('[strips_booked]', testCustomer.strips_booked)
            .replaceAll('[strips_remaining]', testCustomer.total_strips_available)
            .replaceAll('[booked_date]', testCustomer.booked_date)
            .replaceAll('[customer_link]', testCustomer.customer_link)
            // have newlines
            .replace(/\n/g, '<br />')

        setPreviewNewCardAddSubjectext(substitutedNewCardAddSubjectext)

        // eslint-disable-next-line
    }, [emailTemplateBookOffCredits, emailCardTemplate, emailAddNewCardTemplate, emailCustomerSubject, emailCardLeftSubject, emailNewCardAddSubject])

    const getPreviewText = () => {
        return {__html: previewText}
    }

    const getPreviewCardText = () => {
        return {__html: previewCardText}
    }

    const getPreviewAddNewCardText = () => {
        return {__html: previewAddNewCardText}
    }

    const getPreviewTextEmailTocustomer = () => {
        return {__html: previewCustomerSubjectext}
    }

    const getPreviewTextCardLeftSubject = () => {
        return {__html: previewCardLeftSubjectext}
    }

    const getPreviewTextNewCardAddSubject = () => {
        return {__html: previewNewCardAddSubjectext}
    }

    const resetEmailTemplate = () => {
        const restoreVersion: string = persistentStore.user?.settings.emailTemplate
            ? persistentStore.user.settings.emailTemplate
            : defaultTemplateTextDutch

        const restoreCardVersion: string = persistentStore.user?.settings.emailCardTemplate
            ? persistentStore.user.settings.emailCardTemplate
            : defaultCardTemplateTextDutch

        const restoreAddNewCardVersion: string = persistentStore.user?.settings.emailAddNewCardTemplate
            ? persistentStore.user.settings.emailAddNewCardTemplate
            : defaultNewCardAddTemplateTextDutch

        updateEmailTemplate(restoreVersion, restoreCardVersion, restoreAddNewCardVersion)
    }

    // when the form is submitted
    const handleSubmitSettings = (event: any) => {
        event.preventDefault()

        if (!persistentStore.user) {
            return
        }

        startLoading()

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user.settings,
            {
                emailTemplate: emailTemplateBookOffCredits,
                emailCardTemplate: emailCardTemplate,
                emailCardLeft: emailCardLeft,
                emailWhenStripsLow: willSendMailOnLowStrips,
                emailAddNewCardTemplate: emailAddNewCardTemplate,
                emailWhenNewCardMade: willSendMailNewCardMade,
                emailCustomerSubject: emailCustomerSubject,
                emailCardLeftSubject: emailCardLeftSubject,
                emailNewCardAddSubject: emailNewCardAddSubject
            }
        )

        users.updateSettings(updatedSettings, persistentStore.user.id)
            .then(response => {
                if (response.success) {
                    updateEmailTemplate(
                        response.data.user?.settings?.emailTemplate,
                        response.data.user?.settings?.emailCardTemplate,
                        response.data.user?.settings?.emailAddNewCardTemplate,
                    )
                    toast.success(t('Changes saved'))
                } else {
                    toast.error(t('Could not save changes'))
                }
            })
            .catch((error) => {
                captureException(error)
                toast.error(error.response.data.error.details)
            })
            .finally(() => {
                persistentStore.updateUserFromServer()
                stopLoading()
            })
    }

    return (
        <form onSubmit={handleSubmitSettings}>
            <Card className='mt-4 mb-5 Communication'>
                <Card.Body>
                    <h3>{t('Email to customer')}</h3>
                    <p>
                        {t('Create your own emailtext to your customer whenever you book off credits')}.
                    </p>
                    <p>
                        {t('You can use the following substitutions')}.
                    </p>

                    <ul>
                        <li><strong>[name]</strong> - {t('This will be replaced with the users name')}</li>
                        <li>
                            <strong>[strips_booked]</strong> - {t('This will be replaced with the number of strips booked')}
                        </li>
                        <li>
                            <strong>[description]</strong> - {t('This will be replaced with the description of the strips booked')}
                        </li>
                        <li>
                            <strong>[strips_remaining]</strong> - {t('This will be replaced with the number of strips remaining for that customer')}
                        </li>
                        <li>
                            <strong>[booked_date]</strong> - {t('This will be replaced with the date that the strips were booked off')}
                        </li>
                        <li>
                            <strong>[customer_link]</strong> - {t('This will be replaced with the link the customers view page')}
                        </li>
                    </ul>

                    <Row className='mb-3'>
                        <Col sm={6} className='mb-2'>
                            <Form.Group controlId="email_to_cust">
                                <Form.Label>{t('Email subject')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='email_subject'
                                    value={emailCustomerSubject}
                                    onChange={(event) => setEmailCustomerSubject(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6} className='mb-2'></Col>
                        <Col sm={6} className='mb-2'>
                            <textarea
                                className='form-control strips-updated'
                                id='email-template-book-off-credits'
                                name='email-template-book-off-credits'
                                value={emailTemplateBookOffCredits}
                                onChange={(event) => setEmailTemplateBookOffCredits(event.target.value)}
                            />
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <h5>{t('Preview')}</h5>
                            </Row>
                            <Row className='mb-4'>
                                <div style={{fontWeight: 'bold'}}>{t('Subject')}:&nbsp;</div>
                                <div dangerouslySetInnerHTML={getPreviewTextEmailTocustomer()}/>
                            </Row>
                            <Row>
                                <div dangerouslySetInnerHTML={getPreviewText()}/>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mb-3 mt-4">
                        <Col md={12}>
                            <h3>{t('Email to customer when card is running low')}</h3>
                            <Form.Check
                                type="switch"
                                id="switch-mail-runninglowonstrips"
                                checked={willSendMailOnLowStrips}
                                onChange={(e: any) => setWillSendMailOnLowStrips(e.target.checked)}
                                label={t("Automatically send email to customer reminding that strips are running low")}
                            />
                            {willSendMailOnLowStrips === true && (
                                <>
                                    <ul className='mt-3'>
                                        <li><strong>[name]</strong> - {t('This will be replaced with the users name')}</li>
                                        <li>
                                            <strong>[strips_booked]</strong> - {t('This will be replaced with the number of strips booked')}
                                        </li>
                                        <li>
                                            <strong>[description]</strong> - {t('This will be replaced with the description of the strips booked')}
                                        </li>
                                        <li>
                                            <strong>[strips_remaining]</strong> - {t('This will be replaced with the number of strips remaining for that customer')}
                                        </li>
                                        <li>
                                            <strong>[booked_date]</strong> - {t('This will be replaced with the date that the strips were booked off')}
                                        </li>
                                        <li>
                                            <strong>[customer_link]</strong> - {t('This will be replaced with the link the customers view page')}
                                        </li>
                                        <li>
                                            <strong>[salespage_link]</strong> - {t('This will be replaced with the link to your salespage where the customer can buy new cards')}
                                        </li>
                                    </ul>
                                    <Row>
                                        <Col className='mb-3' md={12}>
                                            <Row>
                                                {useTime && (
                                                    <>
                                                        <h5 className='mr-3'>{t('Send mail when this many minutes are left')} : </h5>
                                                        <select className="col-md-1 form-control"
                                                                value={emailCardLeft}
                                                                onChange={(event) => setEmailCardLeft(parseInt(event.target.value, 10))}
                                                        >
                                                            {emailCardTimeLeftValue.map(leftValue => (
                                                                <option key={leftValue} value={leftValue}>
                                                                    {leftValue}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </>
                                                )}
                                                {!useTime && (
                                                    <>
                                                        <h5 className='mr-3'>{t('Send mail when this many strips are left')} : </h5>
                                                        <select className="col-md-1 form-control"
                                                                value={emailCardLeft}
                                                                onChange={(event) => setEmailCardLeft(parseInt(event.target.value, 10))}
                                                        >
                                                            {emailCardLeftValue.map(leftValue => (
                                                                <option key={leftValue}
                                                                        value={leftValue}>{leftValue}</option>
                                                            ))}
                                                        </select>
                                                    </>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col sm={6} className='mb-2'>
                                            <Form.Group controlId="email_card_left">
                                                <Form.Label>{t('Email subject')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name='email_subject'
                                                    value={emailCardLeftSubject}
                                                    onChange={(event) => setEmailCardLeftSubject(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} className='mb-2'></Col>
                                        <Col sm={6}>
                                            <textarea
                                                id='template-mail-runninglowonstrips'
                                                className='form-control'
                                                name='template-mail-runninglowonstrips'
                                                value={emailCardTemplate}
                                                onChange={(event) => setEmailCardTemplate(event.target.value)}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <h5>{t('Preview')}</h5>
                                            </Row>
                                            <Row className='mb-4'>
                                                <div style={{fontWeight: 'bold'}}>{t('Subject')}:&nbsp;</div>
                                                <div dangerouslySetInnerHTML={getPreviewTextCardLeftSubject()}/>
                                            </Row>
                                            <Row>
                                                <div dangerouslySetInnerHTML={getPreviewCardText()}/>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}

                        </Col>
                    </Row>

                    <Row className="mb-3 mt-4">
                        <Col md={12}>
                            <h3>{t('Email to customer when new card is created')}</h3>

                            <Form.Check
                                type="switch"
                                id="custom-switch-2"
                                checked={willSendMailNewCardMade}
                                onChange={(e: any) => setWillSendMailNewCardMade(e.target.checked)}
                                label={t("Automatically send email to customer when a new card is made")}
                            />
                            {willSendMailNewCardMade === true && (
                                <>
                                    <ul className='mt-3'>
                                        <li><strong>[name]</strong> - {t('This will be replaced with the users name')}
                                        </li>
                                        <li>
                                            <strong>[strips_remaining]</strong> - {t('This will be replaced with the number of strips remaining for that customer')}
                                        </li>
                                        <li>
                                            <strong>[customer_link]</strong> - {t('This will be replaced with the link the customers view page')}
                                        </li>
                                    </ul>
                                    <Row>
                                        <Col sm={6} className='mb-2'>
                                            <Form.Group controlId="email_add_new_card">
                                                <Form.Label>{t('Email subject')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name='email_subject'
                                                    value={emailNewCardAddSubject}
                                                    onChange={(event) => setEmailNewCardAddSubject(event.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} className='mb-2'></Col>
                                        <Col sm={6}>
                                            <textarea
                                                className='form-control'
                                                id='email-add-new-card-template'
                                                name='email-add-new-card-template'
                                                value={emailAddNewCardTemplate}
                                                onChange={(event) => setEmailAddNewCardTemplate(event.target.value)}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <h5>{t('Preview')}</h5>
                                            </Row>
                                            <Row className='mb-4'>
                                                <div style={{fontWeight: 'bold'}}>{t('Subject')}:&nbsp;</div>
                                                <div dangerouslySetInnerHTML={getPreviewTextNewCardAddSubject()}/>
                                            </Row>
                                            <Row>
                                                <div dangerouslySetInnerHTML={getPreviewAddNewCardText()}/>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer>
                    <Button
                        id='save-emailtemplate'
                        type="submit"
                        size='lg'
                        variant="primary"
                        className="mr-1"
                        disabled={loading}
                    >
                        {loading &&
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                        {!loading && t('Save')}
                    </Button>

                    <Button
                        variant={'secondary'}
                        onClick={resetEmailTemplate}
                    >
                        {t('Reset')}
                    </Button>
                </Card.Footer>
            </Card>
        </form>
    )
}

export default CommunicationTab;
