import React, {useEffect, useState} from 'react'
import CardList from "./CardList";
import CustomerStats from './CustomerStats'
import {observer} from 'mobx-react'
import {customers} from '../../api/customers'
import {Image} from "react-bootstrap";
import {useRouter} from "../../utils/router";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import persistentStore from "../../stores/persistentStore";
import {useQuery} from "react-query";
import {users} from "../../api/users";
import UseTimeContext from "../../contexts/UseTimeContext";
import {ICard} from "../../interfaces/ICard";

type CompanyInfo = {
    logo: string,
    sale_slug: string
}

const CustomerView = () => {
    const router = useRouter()
    const {t} = useTranslation()
    const customerPublicId = router.query.publicId;
    const [useTime, setUseTime] = useState(persistentStore.user?.settings.useType === 'hours')
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({logo: '', sale_slug: ''})
    const [customerCards, setCustomerCards] = useState<ICard[]>([])
    const [hideSalespage, setHideSalespage] = useState<boolean>(true)
    const [sellerSellsCards, setSellerSellsCards] = useState<boolean>(true)
    const [sellerAllowsBuyButton, setSellerAllowsBuyButton] = useState<boolean>(true)
    const [slugLastBoughtCardType, setSlugLastBoughtCardType] = useState<string|false>(false)
    const customer = useQuery('getReadOnlyCustomer', () => customers.getReadOnlyView(customerPublicId))

    /** Customer Cards **/
    const customerCardsQuery = useQuery(
        ['customerCards', customerPublicId],
        () => customers.cardsForStatusPage(customerPublicId),
        {enabled: customerPublicId}
    )
    useEffect(()=>{
        if(customerCardsQuery.data){
            setCustomerCards(customerCardsQuery.data.cards)
        }
    }, [customerCardsQuery.data])
    /** hide salespage from settings **/

    /** hide salespage from settings **/
    const hidesSalesPageQuery = useQuery(
        'hidesSalesPage',
        () => users.hidesSalesPage(customer.data?.user_id),
        {enabled: customer.data?.user_id}
    )
    useEffect(()=>{
        if(hidesSalesPageQuery.data){
            setHideSalespage(hidesSalesPageQuery.data.hides_salespage)
        }
    }, [hidesSalesPageQuery.data])
    /** hide salespage from settings **/

    const sellsCardsQuery = useQuery(
        'sellsCards',
        () => users.sellsCards(customer.data?.user_id),
        {enabled: customer.data?.user_id}
    )
    useEffect(()=>{
        if(sellsCardsQuery.data){
            setSellerSellsCards(sellsCardsQuery.data.sells_cards)
        }
    }, [sellsCardsQuery.data])

    /** Seller information like the sale slug **/
    const companyInfoQuery = useQuery(
        ['companyInfo', customerPublicId],
        () => users.companyInfoByUser(customerPublicId),
        {enabled: customerPublicId}
    )
    useEffect(()=>{
        if(companyInfoQuery.data){
            setCompanyInfo(prevInfo => ({
                ...prevInfo,
                sale_slug: companyInfoQuery.data?.team.sale_slug,
                logo: companyInfoQuery.data.team.logo
            }))
        }
    }, [companyInfoQuery.data])
    /** /Seller information like the sale slug **/

    /** Seller hides buy button? **/
    const displayBuyButtonQuery = useQuery(
        'displayBuyButton',
        () => users.displayBuyButton(customer.data?.user_id),
        {enabled: customer.data?.user_id}
    )
    useEffect(()=>{
        if(displayBuyButtonQuery.data){
            setSellerAllowsBuyButton(displayBuyButtonQuery.data.display_buy_button)
        }
    }, [displayBuyButtonQuery.data])
    /** Seller hides buy button? **/

    const useTimeQuery = useQuery(
        'useTime',
        () => users.getTimeOrStripsFromCustomer(customerPublicId)
    )
    useEffect(() => {
        if (useTimeQuery.data) {
            setUseTime(useTimeQuery.data === 'hours')
        }
    }, [useTimeQuery.data])

    const slugLastBoughtCardQuery = useQuery(
        'slugLastBoughtCard',
        () => users.getSlugOfLastBoughtCard(customer.data?.id),
        {enabled: customer.data?.id}
    )
    useEffect(()=>{
        if(slugLastBoughtCardQuery.data){
            setSlugLastBoughtCardType(slugLastBoughtCardQuery.data.slug)
        }
    }, [slugLastBoughtCardQuery.data])

    if (customer.isLoading) {
        return <div>Loading</div>
    }

    const showBuyButton: boolean = sellerSellsCards && sellerAllowsBuyButton && ( ! hideSalespage || slugLastBoughtCardType !== false)
    const buyUrl = hideSalespage
        ? `/order/${companyInfo.sale_slug}/${slugLastBoughtCardType}`
        : `/order/${companyInfo.sale_slug}`

    return (
        <UseTimeContext.Provider value={useTime}>
            <div className='container' style={{maxWidth: '600px'}}>
                <div className="customer-view pt-2 justify-content-center">
                    <div className='d-flex flex-row'>
                        {companyInfo.logo && (
                            <div>
                                <Image
                                    width={150}
                                    alt={persistentStore.user?.name}
                                    src={`${persistentStore.apiUrl}/img/logo/${companyInfo.logo}`}
                                />
                            </div>
                        )}

                        {!companyInfo.logo && (
                            <div>
                                <h1>{customer.data.name}</h1>
                            </div>
                        )}

                        {companyInfo.logo && (
                            <div className='flex-sm-grow-1 align-self-center' style={{marginLeft: '20px'}}>
                                <h1>{customer.data.name}</h1>
                            </div>
                        )}

                        {(showBuyButton) && (
                            <div className='ml-auto'>
                                <Button
                                    id='btn-buy'
                                    variant='outline-primary'
                                    onClick={() => router.push(buyUrl)}
                                >
                                    {t('Buy')}
                                </Button>
                            </div>
                        )}
                    </div>

                    {customer.data.customer_text && (
                        <div className="d-flex flex-row">
                            <p className='pt-3'>
                                {customer.data.customer_text.split('\n')
                                    .map((item: string, key: number) => {
                                        return <React.Fragment key={key}>{item}<br/></React.Fragment>
                                    })
                                }
                            </p>
                        </div>
                    )}

                    <CustomerStats customer={customer}/>

                    <CardList
                        cards={customerCards}
                        title={t("Strippenkaarten")}
                        loading={customer.isLoading}
                    />
                </div>
            </div>
        </UseTimeContext.Provider>
    )
}

export default observer(CustomerView)
