import React, {useEffect, useState} from "react";
import {Col, Row, Image, Table} from 'react-bootstrap'
import store from "../../stores/store";
import moment from 'moment';
import {useTranslation} from "react-i18next";
import persistentStore from "../../stores/persistentStore";
import {IUser} from "../../interfaces/IUser";
import {IInvoice} from "../../interfaces/IInvoice";
import uiStore from "../../stores/uiStore";

const InvoiceView = (props: any) => {
    const invoice: IInvoice = props.invoice
    const buyer: IUser = invoice.buyer
    const seller: IUser = invoice.seller
    const {t} = useTranslation()
    const [invoiceStatus, setInvoiceStatus] = useState('')

    const subtotalInCents: number = invoice.subtotal_in_cents
    const vatInCents: number = invoice.vat_in_cents
    const orderTotalInCents: number = invoice.total_price_in_cents
    const vatPercentageFormatted: string = String(invoice.vat_percentage) + '%'

    const InvoiceLogo = (props: any) => {
        const {seller}: { seller: IUser } = props
        return seller?.logo
            ? <Image src={`${persistentStore.apiUrl}/img/logo/${seller.logo}`} alt='logo' style={{width: '100%', height: '100%'}} />
            : <h2>{seller?.name}</h2>
    }

    useEffect(() => {
        if(! invoice){
            return
        }

        switch(invoice.status){
            case 'paid':
                setInvoiceStatus(t('paid'))
                break;
            case 'open':
                setInvoiceStatus(t('open'))
                break;
            default:
                setInvoiceStatus(invoice.status)
        }
    }, [invoice, t])

    if (!invoice) {
        return null
    }

    return (
        <Row className='col-md-12'>
            <Col md={12} className="border p-3">
                <Row>
                    <Col md={6}><InvoiceLogo seller={invoice?.seller}/></Col>
                    <Col md={6} className="text-right">
                        <h3 className="text-uppercase">{invoiceStatus}</h3>
                        <p>{t('Invoice')} #{invoice?.public_id}</p>
                    </Col>
                </Row>
                <Row className="pt-5">
                    <Col md={12}>
                        <Row>
                            <Col md={10}></Col>
                            <Col md={2}>
                                <strong>{t('Date of issue')}</strong>
                                <p>{moment(invoice?.created).format('DD-M-YYYY')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <div>{seller?.name}</div>
                                <div>
                                    {invoice.user_company_address ? invoice.user_company_address : ''}<br />
                                    {invoice.user_company_zipcode ? ", "+invoice.user_company_zipcode : ''}{invoice.user_company_city ? ", "+invoice.user_company_city : ''}
                                </div>
                                {invoice.user_company_coc_number && (<div>{t('CoC')}:{invoice.user_company_coc_number}</div> )}
                                {invoice.user_company_vat_number && (<div>{t('VAT')}: {invoice.user_company_vat_number}</div> )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <strong>{t('Billed to')}</strong>
                                <div>{buyer?.name}</div>
                                <div>{invoice.customer_company_name ? invoice.customer_company_name : ''}</div>
                                <div>
                                    {invoice.customer_company_address ? invoice.customer_company_address : ''}<br />
                                    {invoice.customer_company_zipcode ? invoice.customer_company_zipcode.toUpperCase()+ ' ' : ''}{invoice.customer_company_city ? invoice.customer_company_city : ''}
                                </div>
                                <br />
                                {invoice.customer_company_coc_number && (<div>{t('CoC')}: {invoice.customer_company_coc_number}</div> )}
                                {invoice.customer_company_vat_number && (<div>{t('VAT')}: {invoice.customer_company_vat_number}</div> )}
                                {buyer.invoice_references && (<div dangerouslySetInnerHTML={{__html:buyer.invoice_references.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></div> )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col md={12}>
                        <Row>
                            <Col md={12} className="pt-3">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{t('Description')}</th>
                                            <th className="text-right">{t('Qty')}</th>
                                            <th className="text-right">{t('Price')}</th>
                                            <th className="text-right">{t('Subtotal')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td dangerouslySetInnerHTML={{__html: invoice.description.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></td>
                                            <td className="text-right">{invoice.quantity}</td>
                                            <td className="text-right">{(invoice.price_in_cents / 100).toLocaleString(store.locale, uiStore.moneyOptions)}</td>
                                            <td className="text-right">{((invoice.price_in_cents / 100) * invoice.quantity).toLocaleString(store.locale, uiStore.moneyOptions)}</td>
                                        </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">{t('Subtotal')}</td>
                                        <td className="text-right">{(subtotalInCents/100).toLocaleString(store.locale, uiStore.moneyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">{vatPercentageFormatted} {t('VAT')}</td>
                                        <td className="text-right">{(vatInCents / 100).toLocaleString(store.locale, uiStore.moneyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">{t('Total')}</td>
                                        <td className="text-right">{(orderTotalInCents / 100).toLocaleString(store.locale, uiStore.moneyOptions)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default InvoiceView;
