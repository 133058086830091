import React, {useContext} from 'react';
import CardHeader from './CardHeader'
import CardBody from './CardBody'
import CardFooter from './CardFooter'
import {secondsToTime} from "../../utils/helpers";
import {ICard} from "../../interfaces/ICard";
import UseTimeContext from "../../contexts/UseTimeContext";
import {ICustomer} from "../../interfaces/ICustomer";

type SingleStrippenkaartProps = {
    card: ICard,
    customer?:ICustomer
}

const SingleStrippenkaart = (props: SingleStrippenkaartProps) => {
    const {card, customer} = props
    const useTime: boolean = useContext(UseTimeContext)

    // sum up all units
    card.totalUnitsUsed = useTime
        ? secondsToTime(card.stats.time_in_seconds_used)
        : String(card.stats.strips_used)

    card.totalUnitsLeft = useTime
        ? String(card.stats.time_in_seconds_available)
        : String(card.stats.strips_available)

    return (
        <>
            <CardHeader card={card} />
            <CardBody card={card} customer={customer} />
            <CardFooter card={card}/>
        </>
    )
}
export default SingleStrippenkaart
