import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {stats as statsApi} from "../../api/stats";
import DataTable from "react-data-table-component";
import persistentStore from "../../stores/persistentStore";
import {useTranslation} from "react-i18next";

type MonthResult = {
    year: number,
    month: number,
    month_name: string,
    date: string,
    total_time_in_seconds_on_active_cards: number,
    strips_booked_off: number,
    strips_expired: number,
}

const OrderTable = (props: any) => {
    const [monthlyReport, setMonthlyReport] = useState<MonthResult[]>([])
    const {t} = useTranslation()

    const monthlyReportQuery = useQuery(
        'getMonthlyReport',
        statsApi.getMonthlyStats,
    )

    useEffect(() => {
        if(monthlyReportQuery.data) {
            setMonthlyReport(monthlyReportQuery.data)

            console.log('monthlyReportQuery.data', monthlyReportQuery.data)
        }

    }, [monthlyReportQuery.data])

    const columns = [
        {
            name: t('Month'),
            selector: 'month',
            sortable: true,
            cell: (monthResult: MonthResult) => {
                return `${monthResult.month_name} ${monthResult.year}`
            }
        },
        {
            name: t('Strips sold'),
            sortable: true,
            cell: (monthResult: MonthResult) => monthResult.total_time_in_seconds_on_active_cards
        },
        {
            name: t('Strips booked'),
            sortable: true,
            cell: (monthResult: MonthResult) => monthResult.strips_booked_off
        },
        {
            name: t('Strips expired'),
            sortable: true,
            cell: (monthResult: MonthResult) => monthResult.strips_expired
        },
    ]

    return (
        <div>
            <h3>{t('Results per month')}</h3>
            <DataTable
                className='OrderListView order-list-view'
                columns={columns}
                data={monthlyReport}
                pagination={monthlyReport.length > 15}
                paginationPerPage={persistentStore.pageLength}
                paginationRowsPerPageOptions={persistentStore.pageLengthOptions}
                onChangeRowsPerPage={(rowCount) => persistentStore.setPageLength(rowCount)}
                noHeader={true}
                noDataComponent={<div>{t('No orders')}</div>}
            />
        </div>
    )
}

export default OrderTable;
