import React, {useEffect, useState} from "react";
import {Alert, Container, Row, Spinner} from "react-bootstrap";
import Section from "../../components/Section";
import SectionHeader from "../../components/SectionHeader";
import CardList from "./CardList";
import {useTranslation} from "react-i18next";
import {payments} from "../../api/payments";
import {ICardType} from "../../interfaces/ICardType";
import {toast} from "react-toastify";
import CheckoutModal from "./CheckoutModal";
import persistentStore from "../../stores/persistentStore";
import {IUser} from "../../interfaces/IUser";
import {ISellerSettings} from "../../interfaces/ISellerSettings";
import {IOrderCustomer} from "../../interfaces/IOrderCustomer";

type OrderSectionProps = {
    bg: string,
    bgImage: string,
    bgImageOpacity: number,
    cards: ICardType[],
    loading: boolean,
    seller?: IUser,
    settings: ISellerSettings,
    size: string
    startLoading: () => void,
    stopLoading: () => void
    subtitle: string,
    textColor: string,
    title: string,
}

const OrderSection = (props: OrderSectionProps) => {
    const {t} = useTranslation()
    const {cards, loading, startLoading, stopLoading, settings, seller} = props
    const [showCheckoutModal, setShowCheckoutModal] = useState(false)
    const [chosenCardType, setChosenCardType] = useState<ICardType>()

    useEffect(() => {
        if (persistentStore.chosenCardType) {
            setChosenCardType(persistentStore.chosenCardType)
        }
    }, [])

    // store the chosen card so it can be used when the user hits the back-button
    // or when the user buys again next time
    useEffect(() => {
        if (chosenCardType) {
            persistentStore.setChosenCardType(chosenCardType)
        }
    }, [chosenCardType])

    const placeOrder = (cardType: ICardType) => {
        setChosenCardType(cardType)
        setShowCheckoutModal(true)
    }

    const handleOrderSubmit = ({name, email}: { name: string, email: string }) => {
        if (!chosenCardType) {
            toast.error(t('You must choose a card first'))
            setShowCheckoutModal(false)
            return;
        }

        // close the modal
        setShowCheckoutModal(false)

        startLoading()

        const order: IOrderCustomer = {
            user: {email: email, name: name},
            order: [{quantity: 1, card_type_slug: chosenCardType.slug}],
            payment_method: 'ideal'
        }

        payments.place(order)
            .then((response: any) => {
                if (response.success === false) {
                    toast.error(response.errors.title)
                } else {
                    if (response.data.testOrderMsg) {
                        toast.warning(response.data.testOrderMsg)
                    }

                    // store the order for when the user gets back
                    // The user should not provide all info again
                    persistentStore.setOrder(response.data.order)

                    // depending on the seller-settings, open the payment window in a new window or not
                    if(settings.paymentsInNewWindow){
                        window.open(response.data.checkout_url)
                    }
                    else {
                        window.location = response.data.checkout_url
                    }
                }
            })
            .catch((error) => {
                if (!error.response.data.errors) {
                    toast.error('Er is iets fout gegaan bij het betalen')
                    return
                }
                const errors = error.response.data.errors
                const detail = errors.detail

                toast.error(detail)
            })
            .finally(() => stopLoading())
    }

    return (
        <Section
            bg={props.bg}
            textColor={props.textColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                {seller?.settings.saleStatus === 'test' && (
                    <a
                        className="badge badge-success"
                        style={{padding: '15px'}}
                        href="https://docs.strippenkaart.app/betaalstatus-van-test-naar-live-zetten"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('Payments are in testmode')}<br /><br />
                        Klik om te zien hoe je live kunt gaan
                    </a>
                )}

                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={2}
                    spaced={true}
                    className="text-center"
                />

                <Row className='justify-content-center'>
                    {cards.map((card: ICardType, index: number) => (
                        <CardList
                            card={card}
                            key={index}
                            placeOrder={placeOrder}
                            settings={settings}
                        />
                    ))}
                </Row>
                {loading &&
                <p className="text-center">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                </p>
                }
                {!loading && cards.length === 0 && (<Alert variant='info'>{t('No cards yet')}.</Alert>)}

            </Container>

            {chosenCardType && showCheckoutModal && (
                <CheckoutModal
                    cardType={chosenCardType}
                    show={showCheckoutModal}
                    loading={loading}
                    hide={() => setShowCheckoutModal(false)}
                    handleOrderSubmit={handleOrderSubmit}
                />
            )}

        </Section>
    );
}

export default OrderSection;
