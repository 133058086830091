import React from 'react';
import {Alert, Col} from "react-bootstrap";

type CustomerAlertsProps = {
    customerQuery: any
}

const CustomerAlerts = (props: CustomerAlertsProps) => {
    const {customerQuery} = props

    return (
        <Col>
            {(customerQuery.data?.stats.total_strips_available <= 0) && (customerQuery.data?.total_time_in_seconds_on_active_cards > 0) && (
                <Alert variant='warning' className='no-strips-left col-12' style={{textAlign: 'center'}}>
                    Er zijn geen strippen meer. Je kunt een nieuwe strippenkaart
                    toevoegen met de blauwe knop bovenin.
                </Alert>
            )}

            {(customerQuery.data?.stats.total_strips_available <= 0) && (customerQuery.data?.total_time_in_seconds_on_active_cards === 0) && (
                <Alert variant='warning' className='no-strips-left col-12' style={{textAlign: 'center'}}>
                    Er is nog geen strippenkaart. <br/>
                    Je kunt de eerste strippenkaart toevoegen met de grote blauwe knop hierboven.
                </Alert>
            )}
        </Col>
    )
}

export default CustomerAlerts;
