import {PDFDownloadLink} from '@react-pdf/renderer';
import InvoicePDF from './InvoicePDF'
import './InvoicePage.scss'
import React, {useEffect, useState} from "react";
import {invoice as invoiceApi} from '../../api/invoice'
import {Col, Button, Row, Image} from 'react-bootstrap'
import {useRouter} from "../../utils/router";
import {useTranslation} from "react-i18next";
import persistentStore from "../../stores/persistentStore";
import {IInvoice} from "../../interfaces/IInvoice";
import InvoiceView from "./InvoiceView";
import CustomerDetailsModal from "./CustomerDetailsModal";
import {toast} from "react-toastify";
import {ICompany} from '../../interfaces/ICompany';
import {teams} from '../../api/teams';
import {useQuery, useQueryCache} from "react-query";
import {Loader, Plus} from 'react-feather';

const InvoicePage = () => {
    const {t} = useTranslation()
    const queryCache = useQueryCache()
    const invoiceQuery = useQuery('invoice', () => invoiceApi.get(router.query.public_id))
    const [invoice, setInvoice] = useState<IInvoice>()
    const router = useRouter()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [pdfRenderLoading, setPdfRenderLoading] = useState(true)

    // this is gives PDFView a bit of time to finish the render whenever the invoice has changed
    useEffect(() => {
        setTimeout(
            () => {
                setPdfRenderLoading(false)
            },
            1000
        )
    }, [invoice])

    useEffect(() => {
        if (invoiceQuery.data) {
            setInvoice(invoiceQuery.data)
        }

    }, [invoiceQuery.data])

    const saveCustomerCompany = (companyData: ICompany) => {
        setPdfRenderLoading(true)
        const isExistingCompany = (companyData.id) && (companyData.id !== 0)

        if (isExistingCompany) {
            return updateCustomerCompanyOnInvoice(companyData)
        }

        return addNewCustomerCompanyToInvoice(companyData)
    }

    const addNewCustomerCompanyToInvoice = (company: ICompany) => {
        if(! invoice){
            return
        }

        return teams.addCustomerCompanyToInvoice(company, invoice?.public_id)
            .then((data) => {

                if (data.success === false) {
                    //console.error(data.data.message)
                    //toast.error(data.data.message)
                } else {
                    toast.success(t('company added to your account'))
                    setShowModal(false)
                    queryCache.invalidateQueries('invoice')
                }
            })
            .catch((error) => {
                // create a toast error for each error
                const errors = error.response.data.errors

                // the errors is an object with the field name as key and an array of error messages as value
                Object.keys(errors).forEach((field) => {
                    errors[field].forEach((errorMessage: string) => {
                        toast.error(errorMessage)
                    })
                })
            })
    }

    const updateCustomerCompanyOnInvoice = (company: ICompany) => {
        if (!invoice) {
            return
        }

        teams.updateCustomerCompanyOnInvoice(company, invoice.public_id)
            .then((data) => {

                if (data.success === false) {
                    //console.error(data.data.message)
                    //toast.error(data.data.message)
                } else {
                    toast.success(t('company details updated to your account'))
                    setShowModal(false)
                    queryCache.invalidateQueries('invoice')
                }
            })
    }

    const InvoiceLogo = () => {
        if (persistentStore.user?.logo) {
            return <Image
                src={`${persistentStore.apiUrl}/img/logo/${persistentStore.user?.logo}`}
                alt='logo'
                className='avatar'
            />
        }

        return persistentStore.user?.logo
    }

    if (invoiceQuery.isLoading) {
        return <Loader/>
    }

    return (
        <>
            <div id='invoice-details'>
                <Row className='col-md-12 p-0'>
                    <Col className="text-left mb-2 mt-2">
                        <Button
                            variant="link"
                            onClick={(e) => setShowModal(true)}
                        >
                            <Plus className="feather"/>
                            {t('Add/Update Customer Information')}
                        </Button>
                    </Col>

                    <Col className="text-right mb-2 mt-2">
                        {invoice && !pdfRenderLoading && (
                            <PDFDownloadLink
                                document={<InvoicePDF invoice={invoice}/>}
                                fileName={`strippenkaart-factuur-${router.query.public_id}`}
                            >
                                <Button variant="primary">
                                    {t('Download PDF')}
                                </Button>
                            </PDFDownloadLink>
                        )}
                    </Col>
                </Row>

                {invoice && (
                    <InvoiceView logo={InvoiceLogo} invoice={invoice}/>
                )}
            </div>

            {showModal && (
                <CustomerDetailsModal
                    show={showModal}
                    hide={() => setShowModal(false)}
                    companyData={invoice?.buyer.team}
                    handleSubmitSettings={(updatedCompany: ICompany) => saveCustomerCompany(updatedCompany)}
                />
            )}
        </>
    )
}

export default InvoicePage;
