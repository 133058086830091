import React from 'react';
import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ToolTip from "../../components/ToolTip";

type MetaInfoProps = {
   customerPublicId:string
}

const MetaInfo = (props: MetaInfoProps) => {
    const {t} = useTranslation()
    const {customerPublicId} = props

    const copyLink = (inputElementId: string) => {
        const elInput: HTMLInputElement | null = document.getElementById(inputElementId) as HTMLInputElement
        if (elInput) {
            elInput.select()
            document.execCommand('copy')
            toast.success(t('URL copied'))
        }
    }

    return (
        <Card className="mt-5">
            <Card.Header>
                <h4 className="card-title">Meta</h4>
                <p className="card-subtitle">{t('Extra Options')}</p>
            </Card.Header>

            <Card.Body>
                <div className="form-group">
                    <label className="form-label" htmlFor="link-of-customer">{t('Link to customer')}</label>
                    <div className='d-flex flex-row'>
                        <input
                            type="text"
                            id="link-of-customer"
                            className="form-control"
                            value={`${window.location.origin}/status/${customerPublicId}`}
                            readOnly={true}
                        />
                        <ToolTip text={t('copy url')}>
                            <Button
                                variant={'light'}
                                className='ml-1'
                                onClick={(event) => copyLink('link-of-customer')}
                                data-for='url-customer'
                                data-tip
                            >
                                <FontAwesomeIcon icon={faCopy} className="material-icons"/>
                            </Button>
                        </ToolTip>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default MetaInfo;
