import React, {useContext} from 'react';
import {observer} from "mobx-react";
import {secondsToTime} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import {ICustomer} from "../../interfaces/ICustomer";
import {QueryResult} from "react-query";
import UseTimeContext from "../../contexts/UseTimeContext";

const CustomerStats = (props: any) => {
    const customer: QueryResult<ICustomer> = props.customer
    const useTime: boolean = useContext(UseTimeContext)

    const {t} = useTranslation()

    const bought: string = useTime
        ? secondsToTime(customer.data?.stats?.total_time_in_seconds_on_active_cards || 0)
        : String(customer.data?.stats?.total_amount_on_active_cards)

    const remaining: string = useTime
        ? secondsToTime(customer.data?.stats.total_time_in_seconds_available || 0)
        : String(customer.data?.stats.total_strips_available)

    const booked: string = useTime
        ? secondsToTime(customer.data?.stats.total_time_in_seconds_used || 0)
        : String(customer.data?.stats.total_strips_used)

    return (
        <div className="bg-white border-bottom mb-2">
            <div className="content content-boxed pb-2">
                <div className="row items-push text-center">
                    <div className="col-6 col-md-3 offset-md-2 d-none d-md-block">
                        <div className="font-size-sm font-w600 text-muted text-uppercase">Beschikbaar</div>
                        <div className="link-fx font-size-h3" id='remaining'>
                            {remaining}
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="font-size-sm font-w600 text-muted text-uppercase">{t('booked')}</div>
                        <div className="link-fx font-size-h3" id='booked'>
                        {booked}
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="font-size-sm font-w600 text-muted text-uppercase">Actief tegoed</div>
                        <div className="link-fx font-size-h3" id='bought'>
                        {bought}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(CustomerStats)
